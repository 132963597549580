import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class MobFooter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: ''
        }
        this.emailChange = this.emailChange.bind(this);
        this.subs = this.subs.bind(this);
    }
    emailChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        }
        )
    }
    subs() {
        this.props.sub(this.state.email);
    }
    render() {
        const handleClickCookieControl = () => {  
            if (window.illow) {   
              window.illow.showWidget();  
              console.log("illow is available");  
            } else {  
              console.log('illow is not available');    
            }     
          };        
        return (
            <div>
                <div className="container-fluid mob-footer hidden-sm hidden-md hidden-lg">
                    <img src="images/logo.svg" alt="logo" />
                    <div className="panel-group" id="accordion9" role="tablist" aria-multiselectable="true">
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingThree4">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion4" href="#collapseThree4" aria-expanded="false" aria-controls="collapseThree4">
                                        Products
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree4">
                                <div className="panel-body">
                                    <Link to="/zen-aua"><li >  ZEN AUA/KUA Platform</li></Link>
                                    <Link to="/zen-asa"><li> ZEN ASA Platform</li></Link>
                                    <Link to="/sms"><li>Subsidy Management System</li></Link>
                                    <Link to="/synzap"><li>SYNZAP</li></Link>
                                    <Link to="/ekyc"><li >Offlinet e-KYC</li></Link>
                                    <Link to="/e-sign">
                                        <li>e-Sign</li>
                                    </Link>
                                    <Link to="/digital-affiliation"><li>Digital Affiliation</li></Link>
                                    <Link to="/enach"><li>e-Nach</li></Link>
                                    <Link to="/edo-suite"><li>Edo Suite</li></Link>
                                    <Link to="/getkyc">
                                        <li>GetKYC</li>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingTwo9">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion9" href="#collapseTwo9" aria-expanded="false" aria-controls="collapseTwo9">
                                        Enterprise Suite
                                            </a>
                                </h4>
                            </div>
                            <div id="collapseTwo9" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo9">
                                <div className="panel-body">
                                    <Link to="/ecos"><li >  e-Cos</li></Link>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingThree9">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion9" href="#collapseThree9" aria-expanded="false" aria-controls="collapseThree9">
                                        Tools
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree9" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree9">
                                <div className="panel-body">
                                    <Link to="/lotsms"><li>Lot SMS</li></Link>
                                    <Link to="/panverification"><li>PAN Verification</li></Link>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingThree11">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion9" href="#collapseThree11" aria-expanded="false" aria-controls="collapseThree11">
                                        Company
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree11" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree11">
                                <div className="panel-body">
                                    <Link to="/about">
                                        <li>About</li>
                                    </Link>
                                    <Link to="/leadership">
                                        <li>Leadership</li>
                                    </Link>

                                    <Link to="/clients">
                                        <li>Clients</li>
                                    </Link>
                                    <Link to="/partners">
                                        <li>Partners</li>
                                    </Link>
                                    {/* <Link to={{pathname: '/contact', state: { prevPath: window.location.pathname }}}>
                                            <li>Contact Us</li>
                                            </Link> */}
                                    {/* <Link to="/investers"><li>investers</li></Link> */}
                                    {/* <Link to="/board-of-directors"><li>Board Of Directors</li></Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingTwo12">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion9" href="#collapseTwo12" aria-expanded="false" aria-controls="collapseTwo12">
                                        Careers
                                            </a>
                                </h4>
                            </div>
                            <div id="collapseTwo12" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo12">
                                <div className="panel-body">
                                    <Link href="#" rel="noopener noreferrer">
                                        <li ><span data-toggle="tooltip" data-placement="right" title="Coming Soon">We're Hiring!</span></li></Link>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingThree10">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion9" href="#collapseThree10" aria-expanded="false" aria-controls="collapseThree10">
                                        Case Studies
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree10" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree10">
                                <div className="panel-body">
                                    <Link href="#" rel="noopener noreferrer">
                                        <li><span data-toggle="tooltip" data-placement="right" title="Coming Soon">Muthoot Fincorp</span></li>
                                    </Link>
                                    <Link href="#" rel="noopener noreferrer">
                                        <li><span data-toggle="tooltip" data-placement="right" title="Coming Soon">Videocon</span></li>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="panel panel-default">
                            <div className="panel-heading" role="tab" id="headingThree11">
                                <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#accordion9" href="#collapseThree13" aria-expanded="false" aria-controls="collapseThree10">
                                        Connect with Us
                                    </a>
                                </h4>
                            </div>
                            <div id="collapseThree13" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree11">
                                <div className="panel-body">
                                    <Link to="/contact">
                                        <li>Contact Us</li>
                                    </Link>
                                    <Link to="/privacy-policy">
                                        <li>Privacy Policy</li>
                                    </Link>

                                    <Link to="/disclaimer">
                                        <li>Disclaimer</li>
                                    </Link>
                                    <Link to="/cookie-policy">
                                        <li>Cookie Policy</li>  
                                    </Link>
                                    <a style={{cursor: "pointer"}} onClick={handleClickCookieControl}>      
                                        <li>Cookie preference</li>      
                                    </a>    
                                </div>
                            </div>
                        </div>

                        <div className="mob-subs">
                            {/* <h4>Connect with us</h4> */}
                            <a href="https://www.facebook.com/syntizenindia" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f footer-social-icons"></i></a>
                            {/* <a href="https://www.linkedin.com/company/syntizen/" target="_blank" rel="noopener noreferrer"> <i className="fab fa-instagram footer-social-icons" style={{ fontWeight: 'bold' }}></i></a> */}
                            <a href="https://www.crunchbase.com/organization/syntizen" target="_blank" rel="noopener noreferrer"> <img src="images/cb.png" className="footer-social-icons" alt="no img" style={{ width: "35px", paddingTop: "26px", paddingLeft: "0px" }} /> </a>
                            <a href="https://www.linkedin.com/company/syntizen/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in footer-social-icons"></i></a>
                            <a href="https://twitter.com/syntizenindia" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter footer-social-icons"></i></a>
                            <div style={{color:"#254282",fontSize:"14px",marginBottom:"10px"}}>Report vulnerabilities at <a style={{textDecoration:"underline"}} href="mailto:reportbug@m2pfintech.com">reportbug@m2pfintech.com</a></div>

                            {/* <form className="subscribe_form def-shad" style={{ width: '100%' }} onSubmit={(e)=>{e.preventDefault();window.addrecord()}}>
                                        <div className="input-group">
                                        <input type="hidden" id="mfsource" value="Syntizen Footer"/>
                                            <input type="email" name="email" id="mfemail" className="form-control" placeholder="Your email address" required />
                                            <span className="input-group-btn">
                                              <button className="btn btn-default ekyc-getstarted-btn form-control" type="submit">Get Started
                                            </button>
                                            </span>
                                        </div>
                                    </form> */}
                            <form className="subscribe_form def-shad" style={{ width: '100%' }} action="https://syntizen.us16.list-manage.com/subscribe/post-json?u=debe08ad72138c42e096d172f&amp;id=9b21bb240b&c=?"
                                method="post" id="mob-footer-subscribe-form" name="mob-footer-subscribe-form">
                                <div className="input-group">

                                    <input type="email" name="MERGE0" id="femail" className="form-control" placeholder="Your email address" required />
                                    <input type="hidden" name="MERGE2" id="fsource" value="Syntizen Footer" />

                                    <div aria-hidden="true" hidden={true}>
                                        <input type="text" name="b_debe08ad72138c42e096d172f_9b21bb240b" tabIndex="-1" value="" />
                                    </div>
                                    <span className="input-group-btn">
                                        <button id="mob-subscribe" className="btn btn-default ekyc-getstarted-btn form-control" type="submit">Subscribe</button>
                                    </span>
                                </div>

                            </form>
                            <div >
                                <p id="mob-subscriptionSuccess"> </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default MobFooter;
