import React, { Component } from 'react'
import Aux from '../../../hoc/Aux';
import { Link } from 'react-router-dom';
import MobFooter from './MobFooter';
// const $ = window.$;

export class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: ''
        }
        this.emailChange = this.emailChange.bind(this);
        this.subs = this.subs.bind(this);
    }
    emailChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    subs() {
        this.props.sub(this.state.email);
    }
    render() {
        const handleClickCookieControl = () => {
            if (window.illow) {
              window.illow.showWidget();
              console.log("illow is available");
            } else {
              console.log('illow is not available');
            }
          };
        return (
            <Aux>
                <section className="footer-sec">
                    <div className="container">

                        <div className="row footer-row">
                            <div className="col-md-6 col-xs-12">
                                <h3 style={{ color: '#2b99fc' }}>Ready to get Started?</h3>
                                <h3 style={{ color: '#2b99fc' }}>Get in touch with Sales</h3>

                            </div>
                            <div className="col-md-5 col-md-offset-1 col-xs-12 foot-col-marg ">
                                {/* <a href="#"><button className="btn-back-dark ">Create Account</button></a> */}
                                <Link to={{ pathname: '/contact', state: { prevPath: window.location.pathname } }}><button className="btn-back-white pull-right footer-btn-left-marg">Contact Sales</button></Link>
                            </div>
                        </div>
                        <div className="row footer-row2">
                            <div className="col-md-12">
                                <div className="footer-col1">
                                    <img className="footer-img-logo" src="images/Syntizen_blue.png" alt="no img" />
                                    <Link to="/zen-aua" >
                                        <li>ZEN AUA/KUA Platform</li>
                                    </Link>
                                    <Link to="/zen-asa" >
                                        <li ><span>  ZEN ASA Platform</span></li>
                                    </Link>
                                    <Link to="/sms" >

                                        <li>Subsidy Management System</li>
                                    </Link>
                                    <Link to="/synzap" >

                                        <li>Synzap</li>
                                    </Link>
                                    <Link to="/ekyc" >

                                        <li>Offline e-KYC</li>
                                    </Link>
                                    <Link to="/e-sign">
                                        <li >e-Sign</li>
                                    </Link>
                                    <Link to="/digital-affiliation" >

                                        <li>Digital Affiliation</li>
                                    </Link>

                                    <Link to="/enach"><li >e-Nach</li></Link>
                                    <Link to="/edo-suite"><li >Edo Suite</li></Link>
                                    <Link to="/getkyc">
                                        <li >
                                            GetKYC
                                        </li>
                                    </Link>
                                    <h5>Enterprise Suite</h5>
                                    <Link to="/ecos">
                                        <li>e-COS</li>

                                    </Link>
                                    <Link href="#" rel="noopener noreferrer">
                                        <h5>Tools</h5>
                                    </Link>
                                    <Link to="/lotsms"> <li >Lot SMS</li></Link>

                                    <Link to="/panverification">
                                        <li >PAN Verification</li>

                                    </Link>

                                </div>
                                <div className="footer-col2">
                                    <h4>Company</h4>
                                    <Link to="/about">
                                        <li>About</li>
                                    </Link>
                                    <Link to="/leadership">
                                        <li>Leadership</li>
                                    </Link>

                                    <Link to="/clients">
                                        <li>Clients</li>
                                    </Link>
                                    <Link to="/partners">
                                        <li>Partners</li>
                                    </Link>


                                    {/* <Link to={{ pathname: '/contact', state: { prevPath: window.location.pathname } }}>
                                        <li>Contact Us</li>
                                    </Link> */}
                                    {/* <Link to="/investers">
                                            <li>Investers</li>
                                            </Link> */}
                                    {/* <Link to="board-of-directors">
                                            <li>Board Of Directors</li>
                                            </Link> */}
                                </div>
                                <div className="footer-col3">
                                    <h4>Careers</h4>
                                    <li ><span data-toggle="tooltip" data-placement="right" title="Coming Soon">We're Hiring!</span></li>
                                </div>
                                <div className="footer-col4">
                                    <h4>Case Studies</h4>
                                    <Link href="#" rel="noopener noreferrer">
                                        <li ><span data-toggle="tooltip" data-placement="right" title="Coming Soon">Muthoot Fincorp</span></li>

                                    </Link>
                                    <Link href="#" rel="noopener noreferrer">
                                        <li ><span data-toggle="tooltip" data-placement="right" title="Coming Soon">Videocon</span></li>

                                    </Link>
                                </div>
                                {/* <div className="footer-col5">
                                    <h4>Connect with us</h4>
                                    <a href="#">
                                        <li ><span data-toggle="tooltip" data-placement="right" title="Coming Soon">Muthoot Fincorp</span></li>

                                    </a>
                                    <a href="#">
                                        <li ><span data-toggle="tooltip" data-placement="right" title="Coming Soon">Videocon</span></li>

                                    </a>
                                </div> */}
                                <div className="footer-col5">
                                    <h4>Connect with us</h4>
                                    <Link to="/contact">
                                        <li>Contact Us</li>
                                    </Link>
                                    <Link to="/privacy-policy">
                                        <li>Privacy Policy</li>
                                    </Link>

                                    <Link to="/disclaimer">
                                        <li>Disclaimer</li>
                                    </Link>
                                    <Link to="/cookie-policy">
                                        <li>Cookie Policy</li>
                                    </Link>
                                    <a style={{cursor: "pointer"}} onClick={handleClickCookieControl}>
                                        <li>Cookie preference</li>
                                    </a>
                                    <div className="footer-social-main">
                                        <a href="https://www.facebook.com/syntizenindia" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f footer-social-icons"></i></a>
                                        {/* <a href="https://www.instagram.com/syntizenindia" target="_blank" rel="noopener noreferrer"> <i className="fab fa-instagram footer-social-icons" style={{ fontWeight: 'bold' }}></i></a> */}
                                        <a href="https://www.crunchbase.com/organization/syntizen" target="_blank" rel="noopener noreferrer"> <img src="images/cb.png" alt="no img" className="footer-social-icons" style={{ width: "35px", "paddingTop": "3px" }} /> </a>
                                        {/* <a href="https://www.linkedin.com/company/m2pfintech" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in footer-social-icons"></i></a> */}
                                        <a href="https://twitter.com/syntizenindia" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter footer-social-icons"></i></a>
                                    </div>
                                    <div style={{color:"#254282",fontSize:"14px",marginBottom:"10px"}}>Report vulnerabilities at <a style={{textDecoration:"underline"}} href="mailto:reportbug@m2pfintech.com">reportbug@m2pfintech.com</a></div>


                                    <form className="subscribe_form def-shad" style={{ width: '100%' }} action="https://syntizen.us16.list-manage.com/subscribe/post-json?u=debe08ad72138c42e096d172f&amp;id=9b21bb240b&c=?"
                                        method="post" id="footer-subscribe-form" name="footer-subscribe-form">
                                        <div className="input-group">

                                            <input type="email" name="MERGE0" id="femail" className="form-control" placeholder="Your email address" required />
                                            <input type="hidden" name="MERGE2" id="fsource" value="Syntizen Footer" />

                                            <div aria-hidden={true} hidden={true}>
                                                <input type="text" name="b_debe08ad72138c42e096d172f_9b21bb240b" tabIndex="-1" value="" />
                                            </div>
                                            <span className="input-group-btn">
                                                <button id="subscribe" className="btn btn-default ekyc-getstarted-btn form-control" type="submit">Subscribe</button>
                                            </span>
                                        </div>

                                    </form>
                                    <div >
                                        <p id="subscriptionSuccess"> </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <MobFooter />

                    <div className="col footer-rights text-center">

                        <p>© 2019, Syntizen Technologies Pvt. Ltd. All Rights Reserved.</p>
                    </div>
                </section>

            </Aux>
        )
    }
}

export default Footer;
