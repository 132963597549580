import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Aux from '../../hoc/Aux';
import './NotFound.css';

export class NotFound extends Component {
    render() {
        return (
            <Aux>
                <div id="notfound">
                    <div className="notfound">
                        <div className="notfound-404">
                            <h1>Oops!</h1>
                        </div>
                        <h2>404 - Page not found</h2>
                        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                        <Link to="/"> <button className="def-shad btn-back-dark">Go To Homepage</button> </Link>
                    </div>
                </div>
            </Aux>
        )
    }
}

export default NotFound;
