import React, { Component, Suspense } from 'react'
import Aux from '../../../hoc/Aux';
import Menu from '../Menu/Menu';
import Footer from '../Footer/Footer';
import routes from '../../../routes';
import { Switch, Route, Redirect } from 'react-router-dom';

class DefaultLayout extends Component {
    componentDidUpdate() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    render() {
        return (
            <Aux>
                <Menu />
                <main className='main-wrapper'>
                    {/* {props.children} */}
                    <Suspense fallback={<div>Loading...</div>}>
                        <Switch>
                            {routes.map((route, idx) => {
                                return route.component ? (<Route key={idx} path={route.path} exact={route.exact} render={props => (
                                    <route.component {...props} />
                                )} />) : (null);
                            })}
                            <Redirect from="*" to="/404" />
                        </Switch>
                    </Suspense>
                </main>
            <div className="TopNews">Good news alert. M2P acquires Syntizen. &nbsp; &nbsp; <a href="/pressrelease/july22">Read More</a></div>

                <Footer />
            </Aux>
        )
    }
}

export default DefaultLayout;
